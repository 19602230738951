$primary-color: #1f2695;
$accent-color: #c81d56;
$indigo-color: #75186a;
$dark-blue-color: #10151f;

$white-smoke-color: #f1f2f5;

$light-input-bg: #c2c2c2;

$dark-input-bg: #32363f85;
$dark-outer-bg: #1e1f24;

$spacing: 32px;
$tablet-spacing: 24px;
$mobile-spacing: 16px;

$border-radius: 7px;
$large-border-radius: 15px;

$small-mobile-width: 360px;
$mobile-width: 560px;
$tablet-width: 900px;
$large-tablet-width: 1250px;

//DARK COLORS
$outer-dark-bg-color: #1e1f24;
$inner-dark-bg-color: #17181c;
$dark-blue: #10151f;
$dark-line-divider: rgba(#ffffff, 0.15);
$dark-input: #1e1f23;
$dark-primary-color: #0177fb;

//LIGHT COLORS
$outer-light-bg-color: #ffffff;
$inner-light-bg-color: #f9f9f9;
$white-smoke: #f9fafc;
$light-line-divider: rgba(#000000, 0.15);
$light-input: #f4f4f5;
