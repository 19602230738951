@use './abstracts/' as *;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
}

input,
textarea,
select,
button {
  font-family: 'Inter', sans-serif;
}

button {
  background: transparent;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
}

.fadeIn {
  animation: fadeIn 0.35s forwards;
}

.google-button {
  @include flex();
  padding: 0.7rem 2rem;
  outline: none;
  border-radius: $border-radius;
  border: 1px solid $dark-input-bg;
  width: 100%;
  gap: 2rem;
  margin: 1rem 0;

  img {
    width: 25px;
    height: 25px;
    object-fit: cover;
  }

  .text {
    font-size: 17px;
    font-weight: 500;

    @include mobile() {
      font-size: 14px;
    }
  }

  @include mobile() {
    gap: 1rem;
  }

  .hidden {
    position: absolute;
    opacity: 0;
  }
}

.show-password {
  @include flex(center, flex-start);
  gap: 0.5rem;
  cursor: pointer;
  margin: 1rem 0;
  width: max-content;

  .box {
    width: 20px;
    height: 20px;
    border: 1px solid $dark-input-bg;
    @include flex();
    color: $primary-color;
    border-radius: 2px;
  }
  .label {
    font-size: 13px;
  }
}

.scroll-to-top {
  position: fixed;
  z-index: 100;
  bottom: 3rem;
  right: 2rem;
  background-color: #01042b;
  border: 2px solid $accent-color;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transition: all 0.5s ease;

  display: grid;
  place-items: center;

  img {
    width: 30px;
    height: 100%;
    object-fit: contain;
  }
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

/* Disable default styling for password field */
input[type='password'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

%fadeOpacity {
  animation: fadeIn var(--transition-1) forwards;
}

.fade-opacity {
  @extend %fadeOpacity;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.session__description {
  font-size: 14px;
  line-height: 20px;
  padding-right: 1rem;

  li {
    list-style: inherit;
  }

  ul,
  ol {
    margin-left: 1.5rem !important;
  }

  p {
    padding: 0.3rem 0 !important;
  }

  a {
    color: #123eff !important;
    text-decoration: underline;
    font-weight: 500;
  }
}
